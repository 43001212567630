<template>
  <div class="consortium">
    <!-- <div class="top_Img">
      <img src="../../assets/banner/member.jpg" alt="">
      <div class="tips">成员单位</div>
    </div> -->
     <div v-if="loading" class="spin-loading">
      <a-spin tip="Loading..." size="large">
        <div class="spin-content"></div>
      </a-spin>
    </div>
    <div v-else>
      <div class="memberpic">
        <div class="item">
          <div class="list" v-for="(item,index) of memberList" :key="index" @click="changeDetails(item)">
            <div class="Img"><img :src="item.logo_url" alt=""></div>
            <div class="title">{{item.name}}</div>
          </div>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      memberList:[],
      searchMember:{
        page_index:1,
        per_page_count:999,
        title:''
      },
      loading:true,
    }
  },
  methods:{
    // 跳转详情查看
    changeDetails(data){
      let query = {};
      query.isTop = 'Top';
      query.memberID = data.id;
      this.$router.push({path:'messageDetails',query:query});
    },
    // 获取联合体成员
    getConsortiumMember(){
      this.$post('/v1/member/search',this.searchMember).then(res=>{
        this.memberList = res.data;
        this.$root.$emit('changeImg',res.column_id);
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
  },
  mounted(){
    this.getConsortiumMember();
  }
}
</script>

<style lang="scss" scoped>
  .consortium{
    .memberpic{max-width: 1400px;margin: 0 auto;margin-top: 30px;
      .item{display: flex;flex-wrap:wrap;
        .list{width: calc(25% - 30px);text-align: center;margin:0 15px 30px 15px;border:1px solid #ececec;border-radius:4px;cursor: pointer;
          .Img{height: 100px;
            img{height: 100%;max-width: 100%;}
          }
          .title{background: #f5f5f5;font-size: 12px;color: #252629;line-height: 24px;}
        }
      }
    }
  }
</style>